import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const darkRed = '#9B2C00';
const blue = '#48a0e2';
const gray = '#E9E9E9';
const cyan = '#D2EACF';
const intentionReceiveChartColors = ['#ECC3A3', '#A3C8DC', '#C2DFAE'];
const serviceSatisfactionChartColors = ['#ECC3A3', '#F7DDC6', '#EDD6C4', '#F2E7DD', '#A3C8DC'];
const serviceWareHouseChartColors = ['#ECC3A3', '#F7DDC6', '#A3C8DC', '#C2E0AF'];
const improvementChartColors = [
  '#BACAE3',
  '#D9E0EB',
  '#E8ECF1',
  '#E8ECB3',
  '#A3C8DC',
];
const importWareHouseChartColors = ['#F7BF95', '#FAD5BA', '#FCEADE', '#A0BBE3', '#FBEAA9', '#B8D89E'];
const numberOfAfterCallChartColors = ['#F7BF95', '#A0BBE3', '#B8D89E'];
const colorBorderDottedOutbound = '#B9B6B6DE';

export default {
  black,
  white,
  darkRed,
  blue,
  gray,
  cyan,
  intentionReceiveChartColors,
  serviceSatisfactionChartColors,
  improvementChartColors,
  importWareHouseChartColors,
  numberOfAfterCallChartColors,
  colorBorderDottedOutbound,
  serviceWareHouseChartColors,
  orange: {
    light: '#f77f2c',
    main: '#f96f0f',
    dark: '#f76601',
  },
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100],
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400'],
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white,
    grey: '#EFEEEF',
    aliceBlue1: '#f5f8fa',
    aliceBlue2: '#ebf1f5',
    justDoItBackground: '#01B0F0',
    justDoItAppBackground: '#323F4F',
    commonHeaderRoot: '#EAF2FD',
    backgroundSettingInfo: '#DCDDDD',
    backgroundHinoSos: '#f8eadd',
    antiqueWhite: '#f9ebd7',
  },
  justDoIt: {
    colorTitle: '#01B0F0',
    backgroundCardCount: '#e6e6e6',
    colorButton: '#01B0F0',
    colorBoxShadow: '#0986f3',
    backgroundHeadTable: '#01B0F0',
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  iconCurrentDate: '#1a237e',
  inputAutoFillData: 'rgb(251,224,178)',
  buttonRemove: 'rgb(238,64,79)',
  buttonRemoveHover: '#AE2C37',
  buttonAddHover: '#D0CECE',
  buttonSelectedWorkReport: 'rgb(150, 75, 0)',
  colorDisable: '#263238',
  buttonDelete: '#D66231',
  buttonDeleteHover: '#D26B3F',
  backgroundColorDMShipping: '#464241',
  buttonDispatch: '#95260e',
  buttonDispatchHover: '#ef5350',
  buttonSearchOutBound: '#383332',
  buttonSearchOutBoundHover: '#5a5a5a',
  messageBoardOp: '#6DAAD3',
  salesCompany: '#DDAF00',
  manuscript: '#7D7C7C',
  colorLabel: '#646262',
  buttonRegisterOutbound: '#3f51b5',
  buttonDeleteOutbound: '#999494',
  topHeaderMenuBackground: '#3E3A39',
  headerModalMasterOption: '#3E3A39',
  bottomHeaderMenuBackground: '#898989',
  bottomHeaderTableReportBackground: '#21201E',
  tableOddRowBackground: '#EFEFEF',
  tableRowBorder: '#B6B7B7',
  dropdownButtonColor: '#AFB0B0',
  dropdownBarBackground: '#F2F2F2',
  cancelButtonColor: '#3F3A39',
  submitButtonColor: '#9B2D00',
  paragraphTitleContentColor: '#AE5940',
  backgroundColorInput: '#F1F1F1',
  columnChartTitleContentColor: '#595757',
  colorStackedColumn1: '#F9D586',
  colorStackedColumn2: '#F5AC58',
  colorStackedColumn3: '#F8CA44',
  colorClusteredColumn1: '#EFC09B',
  colorClusteredColumn2: '#A4BADF',
  colorClusteredColumn3: '#BDD6A4',
  rowDottedBorderColor: '#EBEBEB',
  backgroundColorCallRecord: '#f2e3de',
  backgroundColorCallRecordDealer: '#f1f1f1',
  apricotWhite: '#f7f0d8',
  sauvignon: '#f5ebe6',
  blueRomance: '#dcefd7',
  redBrown: '#A03600',
  opalescent: '#BDBDBE',
  lightBlue: '#D5E5F2',
  lightYellow: '#F4E7C3',
  backgroundLavender: '#efeff0',
  backgroundSuvaGrey: '#898989',
  tableReport: {
    greyDark: '#B5B5B6',
    greyLight: '#F2F2F2',
    yellowLight: '#FCF6EA',
    yellowMain: '#F8EDD4',
    yellowDark: '#F9EDD4',
    blueLight: '#F6FBFE',
    blueMain: '#E4F1F9',
    blueDark: '#EEF6FC',
    colorActive: '#E8BF68',
    colorTH: '#646262',
    blackTH: '#3E3A39',
    colorLabel: '#646262',
    rowGreyDark: '#e0e0e0',
    rowGreyLight: '#efefef',
  },
  salesCompanyTable: {
    colorLabel: '#646262',
  },
  labelOutboundColor: '#646262',
  colorNoteOutbound: '#F71C1B',
  colorInputInspection: '#EBD4CC',
  colorInputNo: '#E6E6FA',
  backgroundColorAfterCallTransmissionRecord: '#f1e3dd',
  justDoItBackgroundHover: '#4dc7f4',
  justDoItAppBackgroundHover: '#465260',
  silver: '#C0C0C0',
  whiteSmoke: '#F0F0F0',
  yellowButtonMessage: '#FFFF00FF',
  yellowButtonMessageHover: '#eee99d',
  divOperator: '#6DAAD3',
  divDealer: '#ecc32f',
  divMaker: '#79ec45',
  iconRoundLabelNotification: '#95260e',
  girdListNotificationColor: '#f4e8e3',
  textNotificationColor: '#95260e',
  linkNotificationCodeColor: '#5A95F4',
  linkNotificationCodeHoverColor: '#1c69f8',
  backgroundColorInputSearchOutbound: '#F2F2F2',
  backgroundColorGirdSearch: white,
  eventRowTableListOutboundExternal: '#EFEFEF',
  cellIntentionStoreBlue: '#0080C0',
  cellIntentionStoreGreen: '#3CB371',
  cellIntentionStorePink: '#FF79A7',
  cellIntentionStoreOrange: '#F7A409',
  cellIntentionStoreRed: '#FF0000',
};
