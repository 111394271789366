import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Grid,
  colors,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputIcon from '@material-ui/icons/Input';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { route } from './routes';
import { roles } from 'utils/constants';
import { urlHinoConnect } from 'utils/routes';
import { signOutUser } from 'modules/auth/service';
import { translate } from '../../../utils/intl';
import { urlHinoSOS } from '../../../utils/routes';
import { isExternalAccount } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none !important',
    backgroundColor: theme.palette.background.default,
  },
  flexGrow: {
    flexGrow: 1,
    paddingLeft: 20,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  link: {
    textDecoration: 'none',
    paddingRight: 20,
  },
  user: {
    color: theme.palette.white,
    paddingLeft: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  buttonDirection: {
    borderRadius: 50,
    borderColor: theme.palette.white,
  },
  contentCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  itemScreen: {
    fontSize: 14,
    color: colors.blueGrey[800],
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(38, 50, 56, 0.08)',
    },
  },
  selectedScreen: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.primary.main,
  },
  iconCar: {
    width: '115px',
    marginLeft: '10px',
  },
}));

const Topbar = (props) => {
  const {
    handleDrawerOpen,
    open,
    showMenuIcon,
    className,
    onSidebarOpen,
    userRole,
    hideTopbar = false,
    ...rest
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const pages = route(userRole, roles, user.permissions);
  let pathName = useLocation().pathname;
  let currentPath = pathName.split('/');
  let path = '/' + currentPath[1] + '/' + currentPath[2];

  return hideTopbar !== true ? (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Grid container spacing={3}>
          {showMenuIcon && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}>
              {open ? <ArrowBackIcon /> : <MenuIcon />}
            </IconButton>
          )}
          <Grid className={classes.contentCenter}>
            <Link
              to="/"
              onClick={() => {
                localStorage.setItem('backFormInquiryDetails', false);
                localStorage.setItem(
                  'backFormInquiryDetailsHinoConnect',
                  false,
                );
                localStorage.setItem('backFormInquiryDetailsJustDoIt', false);
                localStorage.setItem(
                  'backFormInquiryDetailsJustDoItFollowCall',
                  false,
                );
                localStorage.setItem(
                  'backFormInquiryDetailsJustDoItCallApplication',
                  false,
                );
              }}>
              <img
                src="/images/Icon_car.png"
                alt="image"
                className={classes.iconCar}
              />
            </Link>
          </Grid>
          <div className={clsx(classes.flexGrow, classes.contentCenter)}>
            {pages.map(
              (page) =>
                page.hasOwnProperty('subMenu') &&
                page.href === '/' + currentPath[1] &&
                page['subMenu'].map((subMenu) => {
                  if (
                    isExternalAccount(userRole) &&
                    (subMenu.path === urlHinoSOS.inquiryForm ||
                      subMenu.path === urlHinoConnect.importFiles ||
                      subMenu.path === urlHinoConnect.inquiryForm)
                  ) {
                    return false;
                  }
                  return (
                    <Link
                      to={subMenu.path}
                      key={subMenu.path}
                      className={classes.link}>
                      <Typography
                        variant="h4"
                        className={clsx(
                          classes.itemScreen,
                          (subMenu.path === pathName ||
                            (subMenu.path === urlHinoConnect.inquiryForm &&
                              path === urlHinoConnect.inquiryDetails) ||
                            (subMenu.path === urlHinoSOS.inquiryForm &&
                              path === urlHinoSOS.inquiryDetails)) &&
                            classes.selectedScreen,
                        )}
                        onClick={() => {
                          localStorage.setItem('backFormInquiryDetails', false);
                          localStorage.setItem(
                            'backFormInquiryDetailsHinoConnect',
                            false,
                          );
                          localStorage.setItem(
                            'backFormInquiryDetailsJustDoIt',
                            false,
                          );
                          localStorage.setItem(
                            'backFormInquiryDetailsJustDoItFollowCall',
                            false,
                          );
                          localStorage.setItem(
                            'backFormInquiryDetailsJustDoItCallApplication',
                            false,
                          );
                        }}>
                        {subMenu.label}
                      </Typography>
                    </Link>
                  );
                }),
            )}
          </div>
          <Hidden mdDown>
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={() => dispatch(signOutUser())}
              title={translate('Sign Out')}>
              <InputIcon />
            </IconButton>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  ) : (
    <></>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
